import React from "react";
import CTA from "../sections/homepage/CTA.js";
import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/about/heroabout.js";
import Brand from "../sections/homepage/Brands.js";
import Trust from '../sections/testimonials/trustpilot.js'
import Head from "../components/head";


const Testimonials = () => {
  return (
    <>
     <Head title="Dermamina Testimonials"  description="5 star rating reviews testimonials Dermamina UK" keywords="Nose jobs, Rhinoplasty, Liquid nose job, Liquid rhinoplasty, Surgical nose job, Surgical nose job, Nose filler, Rhinoplasty filler, Non surgical rhinoplasty, No surgery nose job, Pain free nose job"/>
      <PageWrapper headerDark>
        <Hero title="Testimonials">
       </Hero>
     <Trust />
        <Brand />
      <CTA />
      </PageWrapper>

    </>
  );
};
export default Testimonials;